import "@babel/polyfill";
import ApolloClient from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import fetch from 'unfetch';
import gql from "graphql-tag";
import 'isomorphic-unfetch'


const createApolloClient = (locale) => {
    if (window.location.href.indexOf("preview") != -1){
        var httpLink = createHttpLink({ uri: "../../graphql" });
    }else{
        var httpLink = createHttpLink({ uri: "../graphql" });
    }
    // const httpLink = createHttpLink({ uri: "https://axa-asp-cms.webssup.com/graphql" });

    const localeLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                locale
            }
        }
    });

    return new ApolloClient({
        link: localeLink.concat(httpLink),
        cache: new InMemoryCache(),
        fetchOptions: { fetch },
        uri: process.env.httpLink,
    });
}





const getTopText = (lang, client) => client.query({
    query: gql `
            query GetTopText {
                top_text (
                    locale: "${lang}"
                )
            }
        `
});


const validateCodeWithClient = (code, client) => client.query({
    query: gql `
            query GetChallenge {
                challenge_agent (
                    agent_code: "${code}"
                )
            }
        `
});

const getCategories = (lang, client) => client.query({
    query: gql `
            query GetCategories {
                categories(
                    locale: "${lang}"
                ) {
                    name
                    path_name
                }
            }
        `
});


const getArticles = (path_name, lang, client) => client.query({
    query: gql `
            query GetArticles {
                articles(
                    locale: "${lang}"
                    path_name: "${path_name}"
                ) {
                    id
                    ordering
                    project_name
                    header
                    sub_header
                    cta_message
                    main_body
                    thumbnail
                    thumbnail_portrait
                    enable_facebook
                    facebook_content
                    enable_whatsapp
                    whatsapp_content
                    enable_wechat
                    wechat_content
                    redirect_url
                    ga_parameter
                }
            }
        `
});

const getPreviewArticles = (article_id, client) => client.query({
    query: gql `
            query GetPreviewArticles {
                preview_article(
                    article_id: ${article_id}
                ) {
                    id
                    ordering
                    project_name
                    header
                    sub_header
                    cta_message
                    main_body
                    thumbnail
                    thumbnail_portrait
                    enable_facebook
                    facebook_content
                    enable_whatsapp
                    whatsapp_content
                    enable_wechat
                    wechat_content
                    redirect_url
                    ga_parameter
                }
            }
        `
});

window.validateCode = (code, locale) => validateCodeWithClient(code, createApolloClient(locale))
window.setCategories = (lang, locale) => getCategories(lang, createApolloClient(locale))
window.setArticles = (path_name, lang, locale) => getArticles(path_name, lang, createApolloClient(locale))
window.setPreviewArticles = (article_id, locale) => getPreviewArticles(article_id, createApolloClient(locale))
window.setTopText = (lang, locale) => getTopText(lang, createApolloClient(locale))